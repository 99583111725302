<template>
    <v-text-field v-model="query" placeholder="Search" solo single-line clearable flat filled dense hide-details
        prepend-inner-icon="mdi-magnify" background-color="grey lighten-4" class="tile"></v-text-field>
</template>

<script>
export default {
    data: () => ({
        query: ''
    }),
}
</script>

<style scoped>
.v-text-field.tile .v-input__control .v-input__slot {
    border-radius: 0 !important;
}
</style>