<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="500" max-width="900" persistent scrollable>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                            <span class="warning--text">{{ alertMessage }}</span>
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                <v-text-field v-model="local.name" label="Name" counter="100" :error-messages="nameError"></v-text-field>
                                <v-text-field v-model="local.location" label="Location" counter="75" :error-messages="locationError"></v-text-field>
                                <v-textarea v-model="local.description" label="Description" counter="500" :error-messages="descriptionError"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                <v-row>
                                    <v-col cols="5" lg="4" class="pr-0">
                                    <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on }">
                                        <v-text-field v-model="dateFormatted" label="Date" hint="MM/DD/YYYY format" persistent-hint prepend-icon="mdi-calendar" @blur="date = parseDate(dateFormatted)"
                                            @click:minute="$refs.menu2.save(time)" v-on="on" readonly>
                                        </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" no-title @input="datemenu = false" :min="minDate"></v-date-picker>
                                    </v-menu>
                                    </v-col>
                                    <v-col cols="3" lg="2" class="px-0">
                                    <v-input hint="start time" persistent-hint class="mt-2 pt-3" :error-messages="fromTimeError">
                                        <time-picker v-model="local.fromTime"></time-picker>
                                    </v-input>
                                    </v-col>
                                    <v-col cols="3" lg="2" class="px-0">
                                    <v-input hint="end time" persistent-hint class="mt-2 pt-3" :error-messages="toTimeError">
                                        <time-picker v-model="local.toTime"></time-picker>
                                    </v-input>
                                    </v-col>
                                </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Cancel</v-btn>
                        <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                <span class="warning--text">{{ alertMessage }}</span>
            </v-alert>
            <v-card-text class="ma-0 pa-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                        <v-text-field v-model="local.name" label="Name" counter="100" :error-messages="nameError"></v-text-field>
                        <v-text-field v-model="local.location" label="Location" counter="75" :error-messages="locationError"></v-text-field>
                        <v-textarea v-model="local.description" label="Description" counter="500" :error-messages="descriptionError"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                        <v-row>
                            <v-col cols="5" lg="4" class="pr-0">
                            <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                <v-text-field v-model="dateFormatted" label="Date" hint="MM/DD/YYYY format" persistent-hint prepend-icon="mdi-calendar" @blur="date = parseDate(dateFormatted)"
                                    @click:minute="$refs.menu2.save(time)" v-on="on" readonly>
                                </v-text-field>
                                </template>
                                <v-date-picker v-model="date" no-title @input="datemenu = false" :min="minDate"></v-date-picker>
                            </v-menu>
                            </v-col>
                            <v-col cols="3" lg="2" class="px-0">
                            <v-input hint="start time" persistent-hint class="mt-2 pt-3" :error-messages="fromTimeError">
                                <time-picker v-model="local.fromTime"></time-picker>
                            </v-input>
                            </v-col>
                            <v-col cols="3" lg="2" class="px-0">
                            <v-input hint="end time" persistent-hint class="mt-2 pt-3" :error-messages="toTimeError">
                                <time-picker v-model="local.toTime"></time-picker>
                            </v-input>
                            </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancel</v-btn>
                <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
            </v-card-actions>
        </v-card>    
    </div>
</template>

<script>
import { API } from '@/inc/api';
import moment from 'moment';
import TimePicker from '@/components/TimePicker.vue';
import { _st } from '@/softech';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'New Event'
        },
        dialogSubtitle: {
            type: String,
            default: 'Configure your event'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        nameError: '',
        locationError: '',
        descriptionError: '',
        fromTimeError: '',
        toTimeError: '',
        local: {
            id: 0,
            name: '',
            description: '',
            location: '',
            date: '',
            fromTime: '',
            toTime: '',
            goingQty: '',
        },
        saving: false,
        showAlert: false,
        alertMessage: '',
        alertColor: '',

        date: new Date().toISOString().substr(0, 10),
        minDate: new Date().toISOString().substr(0, 10),
        dateFormatted: '',
        datemenu: false,
    }),
    watch: {
        date() {
            this.dateFormatted = this.formatDateInner(this.date);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.dateFormatted = this.formatDateInner(this.date);
        },
        cancel() {
            this.$emit('cancel');
            this.local = {
                id: 0,
                name: '',
                description: '',
                location: '',
                date: '',
                fromTime: '',
                toTime: '',
                goingQty: '',
            };
            this.date = new Date().toISOString().substr(0, 10);
            
            this.nameError = this.locationError = this.descriptionError = this.fromTimeError = this.toTimeError = '';
            this.showAlert = false;
        },
        load(e) {
            this.local = {
                id:             e.id,
                name:           e.name,
                description:    e.description,
                location:       e.location,
                date:           e.date.date,
                fromTime:       e.date.fromTime,
                toTime:         e.date.toTime,
                goingQty:       e.goingQty
            };

            this.date = this.local.date;
        },
        isValid() {
            let errorFound = false;
            this.nameError = this.locationError = this.descriptionError = this.fromTimeError = this.toTimeError = '';

            if( _st.isNUE( this.local.name ) ) {
                errorFound = true;
                this.nameError = 'required';
            }
            if( _st.isNUE( this.local.location ) ) {
                errorFound = true;
                this.locationError = 'required';
            }
            if( _st.isNUE( this.local.description ) ) {
                errorFound = true;
                this.descriptionError = 'required';
            }
            if( _st.isNUE( this.local.fromTime ) ) {
                errorFound = true;
                this.fromTimeError = 'required';
            }
            if( _st.isNUE( this.local.toTime ) ) {
                errorFound = true;
                this.toTimeError = 'required';
            }
            else {
                let fromTime = moment( this.local.fromTime, 'LTS a' );
                let endTime = moment( this.local.toTime, 'LTS a' );
                if( !fromTime.isBefore(endTime) ) {
                    errorFound = true;
                    this.toTimeError = 'must be later than the start time';
                }
            }

            return !errorFound;
        },
        async save() {
            if( !this.isValid() )
                return;

            let tmpLocal = {
                eventId:        this.local.id,
                name:           this.local.name,
                eventFullDate:  this.date + ' ' + moment( this.local.fromTime, 'LTS a' ).format('HH:mm:ss'),
                eventFromTime:  moment( this.local.fromTime, 'LTS a' ).format('HH:mm:ss'),
                eventToTime:    moment( this.local.toTime, 'LTS a' ).format('HH:mm:ss'),
                eventLocation:  this.local.location,
                description:    this.local.description,
            };

            let api = new API();

            try {
                this.saving = true;
                let res = null;

                if( this.local.id == 0 ) {
                    res = await api.post('/admin/event', tmpLocal);
                }
                else {
                    res = await api.put('/admin/event', tmpLocal);
                }

                this.saving = false;

                if( !res.data.status && res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'error';
                    this.showAlert = true;
                    return;
                }

                if( !res.data.status && !res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'warning';
                    this.showAlert = true;
                    return;
                }

                // is an update
                if( this.local.id != 0 ) {
                    this.$emit('update', res.data.data);
                    this.local.id = res.data.data.id;
                    this.cancel();
                    return;
                }

                this.$emit('saved', res.data.data);
                this.local.id = res.data.data.id;

                // show confirmation message
                
                this.cancel();
            }
            catch(error) {
                this.saving = false;
                console.log(error);
            }
        },
        formatDateInner( date ) {
            if (!date) return null;

            const [year, month, day] = date.split('-');
            return `${month}/${day}/${year}`;
        },
        parseDate( dateFormatted ) {
            if (!dateFormatted) return null;

            const [month, day, year] = dateFormatted.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
    },
    components: {
        TimePicker
    }
}
</script>

<style scoped>

</style>