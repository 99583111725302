import { API }  from '@/inc/api';

export const common = {
    namespaced: true,
    state: {
        buildingTypes       : [],
        unitStatuses        : [],

    }, 
    mutations: {
        setBuildingTypes( state, payload ) {
            state.buildingTypes = payload;
        },
        setUnitStatuses( state, payload ) {
            state.unitStatuses = payload;
        },
    },
    actions: {
        async loadBuildingTypes({ commit }) {
            try {
                let api = new API();
                let res = await api.get( `/admin/building-types` );

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setBuildingTypes', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async loadUnitStatuses({ commit } ) {
            try {
                let api = new API();
                let res = await api.get('/admin/unit-statuses', location);

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setUnitStatuses', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        buildingTypes   : (state) => state.buildingTypes,
        unitStatuses    : (state) => state.unitStatuses,
    }
}