<template>
    <div :class="wrapperClass">
        <input type="text" v-model="hours" size="2" :class="hourFocus ? 'highlight tpInput' : 'tpInput'" @focus="hourFocus = true;$event.target.select();" @blur="hourFocus = false" @input="setHours" @keydown="intOnly($event)" ref="h" /> : 
        <input type="text" v-model="minutes" size="2" :class="minutesFocus ? 'highlight tpInput' : 'tpInput'" @focus="minutesFocus = true;$event.target.select()" @blur="minutesFocus = false" @input="setMinutes" @keydown="intOnly($event)" ref="m" />  
        <input type="text" v-model="ampm" size="2" :class="ampmFocus ? 'highlight tpInput' : 'tpInput'" @focus="ampmFocus = true;$event.target.select()" @blur="ampmFocus = false" @input="setAmpm" ref="ampm" v-if="format == 'ampm'" />
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Time'
        },
        // time format. Possible values ampm, 24hours. Default: ampm
        format: {
            type: String,
            default: 'ampm'
        },
        // disable the component
        disabled: {
            type: Boolean,
            default: false,
        },
        // value of the component
        value: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        hourFocus: false,
        minutesFocus: false,
        ampmFocus: false,

        hours: '--',
        minutes: '--',
        ampm: 'am',
    }),
    computed: {
        formattedTime: function() {
            if( this.format == 'ampm' ) {
                let min = this.minutes == '--' || this.minutes.length == 0 ? '00' : this.minutes;
                if( min.length == 1 )
                    min = '0' + min;

                return (this.hours == '--' || this.hours.length == 0 ? '12' : this.hours) + ':' + min + ' ' + 
                        (this.ampm == '' ? 'am' : this.ampm);
            }
            else if( this.format == '24hours' ) {
                let min = this.minutes == '--' || this.minutes.length == 0 ? '00' : this.minutes;
                if( min.length == 1 )
                    min = '0' + min;

                return (this.hours == '--' || this.hours.length == 0 ? '00' : this.hours) + ':' + min;
            }

            return '--:-- am';
        },
        wrapperClass: function() {
            if( this.hourFocus || this.minutesFocus || this.ampmFocus )
                return 'timePickerWrapper active primary-text';
            
            return 'timePickerWrapper';
        },
    },
    watch: {
        value() {
            this.init();
        },
        hourFocus() {
            if( !this.hourFocus && !this.minutesFocus && !this.ampmFocus )
                this.$emit( 'input', this.formattedTime );
        },
        minutesFocus() {
            if( !this.hourFocus && !this.minutesFocus && !this.ampmFocus )
                this.$emit( 'input', this.formattedTime );this.$emit( 'input', this.formattedTime );
        },
        ampmFocus() {
            if( !this.hourFocus && !this.minutesFocus && !this.ampmFocus )
                this.$emit( 'input', this.formattedTime );this.$emit( 'input', this.formattedTime );
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if( this.value == undefined || this.value == null || this.value == '' )
                return;

            this.hours = this.value.split(':')[0];

            if( this.format == 'ampm' ) {
                let m = this.value.split(':')[1];
                this.minutes = m.substring(0, m.indexOf(' '));
                this.ampm = this.value.split(' ')[1].toLowerCase();
            }
            else if( this.format == '24hours' ) {
                this.minutes = this.value.split(':')[1];
            }
        },
        setHours() {
            let intVal = parseInt(this.hours);
            if( this.format == 'ampm' && ( intVal < 0 || intVal > 12 ) )
                this.hours = this.hours.substring(0, this.hours.length - 1);
            if( this.format == '24hours' && ( intVal < 0 || intVal > 23 ) )
                this.hours = this.hours.substring(0, this.hours.length - 1);

            if( this.hours.length > 2 )
                this.hours = this.hours.substring(0, 2);

            if( this.hours.length > 1 )
                this.$refs.m.focus();

            // this.$emit( 'input', this.formattedTime );
        },
        setMinutes() {
            let intVal = parseInt(this.minutes);
            if( intVal < 0 || intVal > 59 )
                this.minutes = this.minutes.substring(0, this.minutes.length - 1);

            if( this.minutes.length > 2 )
                this.minutes = this.minutes.substring(0, 2);

            if( this.format == 'ampm' && this.minutes.length > 1 )
                this.$refs.ampm.focus();

            // this.$emit( 'input', this.formattedTime );
        },
        setAmpm() {
            if( this.ampm == 'a' )
                this.ampm = 'am';
            else if( this.ampm == 'p' )
                this.ampm = 'pm';
            else {
                this.ampm = this.ampm.substring(0, this.ampm.length - 1);
            }

            if( this.ampm.length == 2 ) {
                this.$refs.ampm.blur();
            }
                
            // this.$emit( 'input', this.formattedTime );
        },
    },
}
</script>

<style scoped>
.timePickerWrapper::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    content: '';
    bottom: -1px;
    left: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
}
.timePickerWrapper.active::before {
    border-bottom: 2px solid #1976d2;
    content: '';
    bottom: -1px;
    left: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
}
input.tpInput {
    /* border:1px solid black; */
    font-size:16px;
    padding:3px 0;
    margin-right: 0;
    margin-left: 0;
    text-align:center;
    color: #000;
}
input.highlight {
    background-color: #e9e9e9;
}
</style>