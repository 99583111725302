import { API }  from '@/inc/api';

export const settings = {
    namespaced: true,
    state: {
        appName             : '',
        stripePK            : '',
        athmovilPublicToken : '',
        topicArn            : '',
    },
    mutations: {
        setStripePK( state, payload ) {
            state.stripePK = payload;
        },
        setAthmovilPublicToken( state, payload ) {
            state.athmovilPublicToken = payload;
        },
        setTopicArn( state, payload ) {
            state.topicArn = payload;
        },
    },
    actions: {
        async load({ commit }) {
            let api = new API();
            
            try {
                let res = await api.get( `/admin/settings` );
                if( res.data.status === true ) {
                    commit('setStripePK', res.data.data.stripePK);
                    commit('setAthmovilPublicToken', res.data.data.athmovilPublicToken);
                    commit('setTopicArn', res.data.data.topicArn);
                }

                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        stripePK            : (state) => state.stripePK,
        athmovilPublicToken : (state) => state.athmovilPublicToken,
        topicArn            : (state) => state.topicArn
    }
}