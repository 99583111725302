<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="500" max-width="900" persistent scrollable>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                            <span class="warning--text">{{ alertMessage }}</span>
                        </v-alert>
                        <v-row>
                            <v-col cols="12" lg="8" class="mx-0">
                                <v-text-field v-model="local.name" label="Name" counter maxlength="100" :error-messages="nameError"></v-text-field>
                            
                                <v-text-field v-model="local.previewDescription" label="Short Description" counter maxlength="150" :error-messages="previewDescriptionError"></v-text-field>
                            
                                <v-textarea v-model="local.description" label="Description" rows="3" counter maxlength="500" :error-messages="descriptionError"></v-textarea>
                            
                                <v-card flat color="blue-grey lighten-5">
                                    <v-card-subtitle>Gallery</v-card-subtitle>
                                    <v-card-text>
                                        <image-picker v-model="local.gallery" multiple primary-image ref="imagePicker"></image-picker>
                                    </v-card-text>
                                </v-card>

                                <v-checkbox v-model="local.active" label="Active"></v-checkbox>
                            </v-col>

                            <v-col cols="12" lg="4" class="mx-0">
                                <v-list two-line>
                                    <v-list-item class="px-0">
                                        <v-list-item-content v-show="!showHoursField">
                                            <v-list-item-title>Hours</v-list-item-title>
                                            <v-list-item-subtitle>{{ hours == '' ? 'Not set' : hours }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-show="!showHoursField">
                                            <v-btn icon @click="showHoursField = true">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-text-field v-model="hours" placeholder="Hours" rounded single-line flat filled dense class="todo-text-field" @keyup.enter="showHoursField = false"  v-show="showHoursField">
                                            <template v-slot:append>
                                                <v-btn color="primary" fab small depressed class="mt-0 pt-0" @click="changeField('hours')">
                                                <v-icon color="white">mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-list-item>

                                    <v-list-item class="px-0">
                                        <v-list-item-content v-show="!showPetsField">
                                            <v-list-item-title>Pets</v-list-item-title>
                                            <v-list-item-subtitle>{{ pets == '' ? 'Not set' : pets }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-show="!showPetsField">
                                            <v-btn icon @click="showPetsField = true">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-text-field v-model="pets" placeholder="Pets" rounded single-line flat filled dense class="todo-text-field" v-show="showPetsField" @keyup.enter="showPetsField = false">
                                            <template v-slot:append>
                                                <v-btn color="primary" fab small depressed class="mt-0 pt-0" @click="showPetsField = false">
                                                <v-icon color="white">mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-list-item>

                                    <v-list-item class="px-0">
                                        <v-list-item-content v-show="!showFoodField">
                                            <v-list-item-title>Food</v-list-item-title>
                                            <v-list-item-subtitle>{{ food == '' ? 'Not set' : food }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-show="!showFoodField">
                                            <v-btn icon @click="showFoodField = true">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-text-field v-model="food" placeholder="Food" rounded single-line flat filled dense class="todo-text-field" v-show="showFoodField" @keyup.enter="showFoodField = false">
                                            <template v-slot:append>
                                                <v-btn color="primary" fab small depressed class="mt-0 pt-0" @click="showFoodField = false">
                                                <v-icon color="white">mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-list-item>

                                    <v-list-item class="px-0">
                                        <v-list-item-content v-show="!showDisclaimerField">
                                            <v-list-item-title>Disclaimer</v-list-item-title>
                                            <v-list-item-subtitle>{{ disclaimer == '' ? 'Not set' : disclaimer }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-show="!showDisclaimerField">
                                            <v-btn icon @click="showDisclaimerField = true">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-text-field v-model="disclaimer" placeholder="Disclaimer" rounded single-line flat filled dense class="todo-text-field" v-show="showDisclaimerField" @keyup.enter="showDisclaimerField = false">
                                            <template v-slot:append>
                                                <v-btn color="primary" fab small depressed class="mt-0 pt-0" @click="showDisclaimerField = false">
                                                <v-icon color="white">mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Cancel</v-btn>
                        <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                <span class="warning--text">{{ alertMessage }}</span>
            </v-alert>
            <v-card-text class="ma-0 pa-0">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="local.name" label="Name" counter maxlength="100" :error-messages="nameError"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="local.previewDescription" label="Short Description" counter maxlength="150" :error-messages="previewDescriptionError"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="local.description" label="Description" rows="3" counter maxlength="500" :error-messages="descriptionError"></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="local.active" label="Active"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-card flat color="blue-grey lighten-5">
                            <v-card-subtitle>Gallery</v-card-subtitle>
                            <v-card-text>
                                <image-picker v-model="local.gallery" multiple primary-image></image-picker>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancel</v-btn>
                <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
            </v-card-actions>
        </v-card>    
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { _st } from '@/softech';
import ImagePicker from '@/components/ImagePicker.vue';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'New Resident'
        },
        dialogSubtitle: {
            type: String,
            default: 'Add new resident to your community'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        nameError: '',
        previewDescriptionError: '',
        descriptionError: '',
        local: {
            id: 0,
            name: '',
            previewDescription: '',
            description: '',
            active: 1,
            dateAdded: new Date().toISOString().substr(0, 10),
            imageSrc: '',
            imageLazySrc: '',
            gallery: [],
            rules: {
                hours: '',
                pets: '',
                food: '',
                disclaimer: ''
            }
        },
        saving: false,
        showAlert: false,
        alertMessage: '',
        alertColor: '',

        hours: '',
        showHoursField: false,
        pets: '',
        showPetsField: false,
        food: '',
        showFoodField: false,
        disclaimer: '',
        showDisclaimerField: false,
        
    }),
    computed: {
        amenity() {
            return {
                id: this.local.id,
                name: this.local.name,
                previewDescription: this.local.previewDescription,
                description: this.local.description,
                active: this.local.active,
                dateAdded: this.local.dateAdded,
                imageSrc: this.local.imageSrc,
                rules: this.local.rules
            }
        }
    },
    watch: {
        dialogOpen(v) {
            if( !v ) {
                this.$refs.imagePicker.clearImages();
            }
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel');
            this.local = {
                id: 0,
                name: '',
                previewDescription: '',
                description: '',
                active: 1,
                dateAdded: new Date().toISOString().substr(0, 10),
                gallery: [],
                rules: []
            };
            
            this.nameError = this.previewDescriptionError = this.descriptionError = '';
            this.hours = this.pets = this.food = this.disclaimer = '';
            this.showAlert = false;
        },
        isValid() {
            let errorFound = false;
            this.nameError = this.previewDescriptionError = this.descriptionError = '';

            if( _st.isNUE( this.local.name.length ) ) {
                this.nameError = 'required';
                errorFound = true;
            }
            if( _st.isNUE( this.local.previewDescription.length ) ) {
                this.previewDescriptionError = 'required';
                errorFound = true;
            }
            // if( this.local.description.length == 0 ) {
            //     this.descriptionError = 'required';
            //     errorFound = true;
            // }

            return !errorFound;
        },
        load(r) {
            let hours = r.rules.find(rule => { return rule.name == 'hours'; });
            let pets = r.rules.find(rule => { return rule.name == 'pets'; });
            let disclaimer = r.rules.find(rule => { return rule.name == 'disclaimer'; });
            let food = r.rules.find(rule => { return rule.name == 'food'; });

            this.local = {
                id: r.id,
                name: r.name,
                previewDescription: r.previewDescription,
                description: r.description,
                active: r.active,
                dateAdded: r.dateAdded,
                imageSrc: r.imageSrc,
                imageLazySrc: r.imageLazySrc,
                gallery: r.gallery,
                rules: {
                    hours: _st.isNUE( hours ) ? '' : hours,
                    pets: _st.isNUE( pets ) ? '' : pets,
                    food: _st.isNUE( food ) ? '' : food,
                    disclaimer: _st.isNUE( disclaimer ) ? '' : disclaimer
                }
            };

            this.hours = _st.isNUE( hours ) ? '' : hours.description;
            this.pets = _st.isNUE( pets ) ? '' : pets.description;
            this.food = _st.isNUE( food ) ? '' : food.description;
            this.disclaimer = _st.isNUE( disclaimer ) ? '' : disclaimer.description;
        },
        async save() {
            if( !this.isValid() )
                return;

            let api = new API();
            let tmpLocal = {
                id: this.local.id,
                name: this.local.name,
                previewDescription: this.local.previewDescription,
                description: this.local.description,
                active: this.local.active,
                gallery: this.local.gallery,
                rules: {
                    hours: { name: 'hours', description: this.hours },
                    pets: { name: 'pets', description: this.pets },
                    food: { name: 'food', description: this.food },
                    disclaimer: { name: 'disclaimer', description: this.disclaimer },
                }
            };

            try { 
                this.saving = true;
                let res = null;
                
                if( this.local.id == 0 ) {
                    res = await api.post('/admin/amenity', tmpLocal);
                }
                else {
                    res = await api.put('/admin/amenity', tmpLocal);
                }

                this.saving = false;
                if( !res.data.status && res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'error';
                    this.showAlert = true;
                    return;
                }

                if( !res.data.status && !res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'warning';
                    this.showAlert = true;
                    return;
                }

                // is an update
                if( this.local.id != 0 ) {
                    this.$emit('update', res.data.data);
                    this.local.id = res.data.data.id;
                    this.cancel();
                    return;
                }

                this.$emit('saved', res.data.data);
                this.local.id = res.data.data.id;

                // show confirmation message
                
                this.cancel();
            }
            catch(error) {
                this.saving = false;
                console.log(error);
            }
        },
        isRuleEmpty( rule ) {
            return _st.isNUE( rule );
        },
        changeField( type ) {
            switch( type ) {
                case 'hours':
                    this.showHoursField = false;
                    this.local.rules.hours.description = this.hours;
                    break;
                case 'pets':
                    this.showPetsField = false;
                    this.local.rules.pets.description = this.pets;
                    break;
                case 'food':
                    this.showFoodField = false;
                    this.local.rules.food.description = this.food;
                    break;
                case 'disclaimer':
                    this.showDisclaimerField = false;
                    this.local.rules.disclaimer.description = this.disclaimer;
                    break;
            }
        }
    },
    components: {
        ImagePicker
    }
}
</script>

<style scoped>
.v-list .v-text-field__details {
    display: none;
}
.v-text-field.todo-text-field .v-input__control .v-input__slot {
    padding-right: 0 !important;
}
.v-text-field.todo-text-field .v-input__append-inner {
    margin-top: 0 !important;
}
</style>