import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        meta: { isLoginPage: true, requiresAuth: false },
        component: () => import('./views/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } },
            ], 
        },
        component: () => import('./views/Dashboard.vue')
    },
    {
        path: '/events',
        name: 'events',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Events', to: { path: '/events' } }
            ],
        },
        component: () => import('./views/Events.vue')
    },
    {
        path: '/organizations',
        name: 'Organizations',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Organizations', to: { path: '/organizations' } }
            ],
        },
        component: () => import('./views/Organizations.vue')
    },
    {
        path: '/residents',
        name: 'Residents',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Residents', to: { path: '/residents' } },
            ], 
        },
        component: () => import('./views/Residents.vue')
    },
    {
        path: '/amenities',
        name: 'Amenities',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Amenities', to: { path: '/amenities' } },
            ],
        },
        component: () => import('./views/Amenities.vue')
    },
    {
        path: '/units',
        name: 'Units',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Units', to: { path: '/units' } },
            ], 
        },
        component: () => import('./views/Units.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Settings', to: { path: '/settings' } },
            ],
        },
        component: () => import('./views/Settings.vue')
    },
    {
        path: '/reports',
        name: 'Reports',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Reports', to: { path: '/reports' } },
            ], 
        },
        component: () => import('./views/Reports.vue')
    },
    {
        path: '/documents',
        name: 'Documents',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Documents', to: { path: '/documents' } },
            ], 
        },
        component: () => import('./views/Documents.vue')
    },
    {
        path: '/wall',
        name: 'Wall',
        meta: { 
            requiresAuth: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/dashboard' } }, 
                { text: 'Wall', to: { path: '/wall' } },
            ], 
        },
        component: () => import('./views/Wall.vue')
    },

    // super admin routes
    // {
    //     path: '/su/dashboard',
    //     name: 'Dashboard',
    //     meta: { requiresAuth: true, requiresSU: true },
    //     component: () => import('./views/AdminDashboard.vue')
    // },
    {
        path: '/su/locations',
        name: 'Locations',
        meta: { 
            requiresAuth: true, 
            requiresSU: true, 
            breadcrumb: [ 
                { text: 'Home', to: { path: '/su/dashboard' } }, 
                { text: 'Locations' } 
            ]},
        component: () => import('./views/Locations.vue')
    },
    {
        path: '/su/locations/:locationId',
        name: 'Locations',
        props: true,
        meta: { 
            requiresAuth: true, 
            requiresSU: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/su/dashboard' } }, 
                { text: 'Locations', to: { path: '/su/locations' } },
                { text: 'Location' }
            ],
        },
        component: () => import('./views/Location.vue')
    },{
        path: '/su/users',
        name: 'Users',
        meta: { 
            requiresAuth: true, 
            requiresSU: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/su/dashboard' } }, 
                { text: 'Users', to: { path: '/su/users' } },
            ],
        },
        component: () => import('./views/Users.vue')
    },{
        path: '/su/users/:userId',
        name: 'Users',
        props: true,
        meta: { 
            requiresAuth: true, 
            requiresSU: true,
            breadcrumb: [ 
                { text: 'Home', to: { path: '/su/dashboard' } }, 
                { text: 'Users', to: { path: '/su/users' } },
                { text: 'User' }
            ],
        },
        component: () => import('./views/User.vue')
    },

    // other routes
    {
        path: '/forbidden',
        name: 'Forbidden',
        meta: { requiresAuth: false, requiresSU: false },
        component: () => import('./views/Forbidden.vue')
    },
    {
        path: '/404',
        name: 'Not Found',
        meta: { requiresAuth: false, requiresSU: false },
        component: () => import('./views/NotFound.vue')
    },

    {
        path: '*',
        meta: { requiresAuth: true, requiresSU: false },
        component: () => import('./views/NotFound.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    // await store.dispatch('auth/authAction');

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresSU = to.matched.some(record => record.meta.requiresSU);
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    const isSU = store.getters['auth/isSU'];

    // console.log( requiresAuth, isLoggedIn, from.fullPath, to.fullPath );

    if( requiresAuth ) {
        if( isLoggedIn ) {
            if( store.getters['auth/location']?.blockMissingPayment && to.path !== '/settings' ) {
                next('/settings');
            }
            else if( requiresSU && !isSU ) {
                next('/forbidden');
            }
            else {
                next();
            }
        }
        else {
            // go to login page
            next('/');
        }
    }
    else {
        next();
    }
})

router.afterEach((to) => {
    Vue.nextTick(() => { document.title = to.name ? `Urbi Admin Panel | ${to.name}` : 'Urbi Admin Panel' });
});

export default router;