import { API }  from '@/inc/api';

export const paymentFrequency = {
    namespaced: true,
    state: {
        paymentFrequency: null,
    }, 
    mutations: {
        setPaymentFrequency( state, payload ) {
            state.paymentFrequency = payload;
        },
    },
    actions: {
        async load({ commit }) {
            let api = new API();
            
            try {
                let res = await api.get( `/admin/payment-frequency` );
                if( res.data.status === true ) {
                    commit('setPaymentFrequency', res.data.data);
                }

                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        paymentFrequency: (state) => state.paymentFrequency,
    }
}