import { API }  from '@/inc/api';

export const documents = {
    namespaced: true,
    state: {
        documents   : [],
        needRefresh : true,
    }, 
    mutations: {
        setDocuments( state, payload ) {
            state.documents = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        },
    },
    actions: {
        async load({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get( `/admin/documents` );

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setDocuments', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async saveDocument({ commit }, document ) {
            try {
                let fData = new FormData();
                fData.append('name', document.name);
                fData.append('description', document.description);
                fData.append('isPublished', document.isPublished);
                fData.append('src', document.src);

                let api = new API();
                let res = null;
                if( document.id == 0 ) {
                    res = await api.post('/admin/document', fData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }
                else {
                    res = await api.put('/admin/document', document);
                }

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async deleteDocument({ commit }, documentId ) {
            try {
                let api = new API();
                let res = await api.delete(`/admin/document/${documentId}`);

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject( error );
            }
        }
    },
    getters: {
        documents           : (state) => state.documents,
        publishedLocations  : (state) => state.documents.filter(u => u.isPublished === true),
        needRefresh         : (state) => state.needRefresh,
    }
}