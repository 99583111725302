import { API }  from '@/inc/api';

export const locations = {
    namespaced: true,
    state: {
        locations   : null,
        needRefresh : true,
    }, 
    mutations: {
        setLocations( state, payload ) {
            state.locations = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        },
    },
    actions: {
        async load({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get( `/admin/locations` );

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setLocations', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async saveLocation({ commit }, location ) {
            try {
                let api = new API();
                let res = await api.post('/admin/location', location);

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        locations       : (state) => state.locations,
        activeLocations : (state) => state.locations.filter(u => u.active === true).sort((a,b) => {
            if( a.name < b.name ) return -1;
            else if( a.name > b.name ) return 1;
            else return 0;
        }),
        needRefresh     : (state) => state.needRefresh,
    }
}