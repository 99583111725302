import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store/index'
import moment from 'moment'
import { _st } from './softech';

export const bus = new Vue();

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    timeAgo(date) {
      return moment( date ).fromNow();
    },
    formatMoney(n) {
      return _st.toMoney(n);
    },
    formatMoneyWithoutSymbol(n, c, d, t) {
      c = isNaN(c = Math.abs(c)) ? 2 : c;
      d = d == undefined ? "." : d;
      t = t == undefined ? "," : t;
      let s = n < 0 ? "-" : "";
      let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
      let j = (j = i.length) > 3 ? j % 3 : 0;
    
      return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    },
    formatPhoneNum(n) {
      return _st.toPhoneNumber(n);
    },
    formatDate(date, format) {
      if( _st.isNUE( date ) )
        return '';
        
      return moment(date).format(format);
    },
    isObjectEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    },
    mxShowMessage( text, color, timeout = 6000 ) {
      bus.$emit('alert', { text, color, timeout });
    },
    isTokenValid() {
      return this.$store.getters.isUserLoggedIn;
    },
    intOnly(e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
          // Allow: Ctrl/cmd+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: Ctrl/cmd+C
      (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: Ctrl/cmd+X
      (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return;
      }

      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    },
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
