<template>
  <v-breadcrumbs :items="breadCrumbs" class="pa-3">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" class="crumb-item" :disabled="item.disabled" exact>
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadcrumb === "function") {
        return this.$route.meta.breadcrumb.call(this, this.$route);
      }
      return this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style>