import { API }  from '@/inc/api';

export const units = {
    namespaced: true,
    state: {
        units       : [],
        needRefresh : true,
    }, 
    mutations: {
        setUnits( state, payload ) {
            state.units = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        },
    },
    actions: {
        async load({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get( `/admin/units` );

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setUnits', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        // async save({ commit }) {

        // },
        async activate({ commit }, { userId, active }) {
            
            try {
                let api = new API();
                let res = await api.post('/admin/unit/activate', { userId, active });

                if( res.data.status !== true ) {
                    return Promise.reject( res.data )
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async getResidents(_, unitId) {
            try {
                let api = new API();
                let res = await api.get(`/admin/unit/${unitId}/residents`);

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }
                
                return Promise.resolve( res.data.data );
            }
            catch( error ) {
                return Promise.reject( error );
            }
        },
        setNeedRefresh({ commit }, needRefresh) {
            commit('setNeedRefresh', needRefresh);
        }
    },
    getters: {
        units           : (state) => state.units,
        activeUnits     : (state) => state.units.filter(u => u.status.id === 1),
        needRefresh     : (state) => state.needRefresh,
    }
}