import { API }  from '@/inc/api';

export const users = {
    namespaced: true,
    state: {
        users       : null,
        needRefresh : true,
    }, 
    mutations: {
        setUsers( state, payload ) {
            state.users = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        },
    },
    actions: {
        async load({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get( `/admin/users` );

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('setUsers', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        // async save({ commit }) {

        // },
        async activate({ commit }, { userId, active }) {
            
            try {
                let api = new API();
                let res = await api.post('/admin/user/activate', { userId, active });

                if( res.data.status !== false ) {
                    return Promise.reject( res.data )
                }

                commit('setNeedRefresh', true);
                Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        users           : (state) => state.users,
        activeUsers     : (state) => state.users.filter(u => u.active === true).sort((a,b) => {
            if( a.fullName < b.fullName ) return -1;
            else if( a.fullName > b.fullName ) return 1;
            else return 0;
        }),
        superAdmins     : (state) => state.users.filter(u => u.privileges?.superAdmin??false),
        needRefresh     : (state) => state.needRefresh,
    }
}