import { API }  from '@/inc/api';

export const wall = {
    namespaced: true,
    state: {
        posts       : [],
        hasMore     : true,
        offset      : 0,
    }, 
    mutations: {
        setPosts( state, payload ) {
            state.posts = payload;
        },
        addPosts( state, payload ) {
            state.posts = state.posts.concat( payload );
        },
        setHasMore( state, payload ) {
            state.hasMore = payload;
        },
        setOffset( state, payload ) {
            state.offset = payload;
        }
    },
    actions: {
        async load({ commit, state }) {
            if( !state.hasMore ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get( `/admin/wall/${state.offset}/20` );

                if( res.data.status !== true ) {
                    return Promise.reject( res.data );
                }

                commit('addPosts', res.data.data);
                commit('setHasMore', res.data.data.length == 20 );
                commit('setOffset', state.offset + 20);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        refresh({ commit }) {
            commit('setPosts', []);
            commit('setHasMore', true);
            commit('setOffset', 0);
        }
    },
    getters: {
        posts           : (state) => state.posts,
        needRefresh     : (state) => state.needRefresh,
    }
}